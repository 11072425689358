import React, { useState } from 'react';
import Popover from 'antd/lib/popover';

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { navigate, Link } from 'gatsby';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import jobs from '../data/jobs.json';

import { Section } from './common';
import breakpoints from '../utils/breakpoints';
import safeScrollTo from '../utils/safeScrollTo';
import LanguageSwitcher from './LanguageSwithcer';

const getSelected = href => {
  if (href.includes('#contact-us')) return '#contact-us';
  if (href.includes('#our-services')) return '#our-services';
  if (href.includes('#our-products')) return '#our-products';
  if (href.includes('#life-in-continero')) return '#life-in-continero';
  if (href.includes('/career')) return '/career';
  if (href.includes('/tomtit')) return '/tomtit';
  return '';
};

const Navbar = () => {
  const href = typeof window !== 'undefined' ? window.location.href : '';

  const intl = useIntl();
  const { locale } = intl;

  const [visible, setNavbarVisible] = useState(false);
  const [isSelected, setSelected] = useState(getSelected(href));

  const handleScrollTop = section => {
    setNavbarVisible(false);
    setSelected(section);

    if (href.includes('Tomtit')) {
      return navigate(`/${locale}/${section}`);
    }

    if (href.includes('career')) {
      return navigate(`/${locale}/${section}`);
    }

    console.log(section)

    return safeScrollTo(section);
  };

  const handleVisibleChange = visible => {
    setNavbarVisible(visible);
  };

  const content = (
    <>
      {/* <MenuItem
        selected={isSelected === '#what-we-do'}
        onClick={() => handleScrollTop('#what-we-do')}
      >
        <FormattedMessage id="Navigation_home" />
      </MenuItem> */}
      
      <MenuItem
        selected={isSelected === '/tomtit'}
        onClick={() => {
          navigate(`/${locale}/tomtit`);
          setSelected('/tomtit');
        }}
      >
        <FormattedMessage id="Navigation_Tomtit" />
      </MenuItem>
      
      <MenuItem
        selected={isSelected === '#our-services'}
        onClick={() => handleScrollTop('#our-services')}
      >
        <FormattedMessage id="Navigation_ourServices" />
      </MenuItem>
      <MenuItem
        selected={isSelected === '#our-products'}
        onClick={() => handleScrollTop('#our-products')}
      >
        <FormattedMessage id="Navigation_ourProducts" />
      </MenuItem>

      <MenuItem
        onClick={() => handleScrollTop('#life-in-continero')}
        selected={isSelected === '#life-in-continero'}
      >
        <FormattedMessage id="Navigation_lifeInContinero" />
      </MenuItem>

      <MenuItem
        selected={isSelected === '/career'}
        onClick={() => {
          navigate(`/${locale}/career`);
          setSelected('/career');
        }}
      >
        <Relative>
          <FormattedMessage id="Navigation_career" />

          <RedCircle>{jobs.length}</RedCircle>
        </Relative>
      </MenuItem>

      {/* <MenuItem
        selected={isSelected === '#blog'}
        onClick={() => handleScrollTop('#blog')}
      >
        <FormattedMessage id="Navigation_blog" />
      </MenuItem> */}
      <MenuItem
        selected={isSelected === '#contact-us'}
        onClick={() => handleScrollTop('#contact-us')}
      >
        <FormattedMessage id="Navigation_contactUs" />
      </MenuItem>
    </>
  );

  return (
    <Wrapper id="header-logo">
      <NavbarSection>
        <Link to="/">
          <TextLogo>Continero</TextLogo>
        </Link>
        <ListDesktop>
          <LanguageSwitcherWrapper />

          <NavigationList>{content}</NavigationList>

          <HamburgerMenu>
            <Popover
              placement="bottomRight"
              title={null}
              content={<HamburgerMenuWrapper>{content}</HamburgerMenuWrapper>}
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <MenuOutlined />
            </Popover>
          </HamburgerMenu>
        </ListDesktop>
      </NavbarSection>
    </Wrapper>
  );
};

const LanguageSwitcherWrapper = styled(LanguageSwitcher)`
  margin-right: 48px;
`;

const HamburgerMenuWrapper = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`;

const Relative = styled.div`
  position: relative;
  color: inherit;
`;

const RedCircle = styled.div`
  background: #fb5b5b;
  position: absolute;
  width: 18px;
  height: 18px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  top: -6px;
  right: -16px;
`;

const MenuItem = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #212f3b;
  display: flex;
  font-weight: 600;

  * {
    font-weight: 600;
  }
  cursor: pointer;

  ${({ selected }) => selected && 'color: #fcaf4a;'}
`;

export default Navbar;

const HamburgerMenu = styled.div`
  display: block;

  ${breakpoints.tablet} {
    display: none;
  }
`;

const ListDesktop = styled.div`
  display: flex;
`;

const NavigationList = styled.div`
  display: none;

  ${breakpoints.tablet} {
    display: flex;
  }

  & > *:not(:last-child) {
    margin-right: 32px;
  }
`;

const NavbarSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TextLogo = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  margin-right: 16px;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const Wrapper = styled(Section)`
  display: flex;
  align-items: center;
  padding: 8px 0;
  position: relative;
  z-index: 2;

  ${breakpoints.tablet} {
    padding: 32px 0;
  }
`;

// const NavItem = styled.a`
//   padding-bottom: 8px;
//   border-bottom: 2px solid transparent;
//   transition: all 200ms ease;

//   cursor: pointer;
//   font-size: 18px;
//   font-weight: 500;
//   text-decoration: none;
//   margin-left: 32px;

//   &:hover {
//     padding-bottom: 4px;
//     border-style: none none solid;
//     border-width: 2px;
//   }
// `;
