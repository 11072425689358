import React from 'react';
import styled from 'styled-components';

import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';

import csFlag from '../assets/cs-flag.svg';
import enFlag from '../assets/en-flag.svg';

const LanguageSwitcher = props => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(
          language =>
            currentLocale !== language && (
              <SwitherWrapper
                {...props}
                key={language}
                onClick={() => changeLocale(language)}
              >
                <FlagImage src={currentLocale === 'en' ? csFlag : enFlag} />
                {language}
              </SwitherWrapper>
            )
        )
      }
    </IntlContextConsumer>
  );
};

export default LanguageSwitcher;

const FlagImage = styled.img`
  width: 16px;
  margin-right: 8px;
`;

const SwitherWrapper = styled.div`
  cursor: pointer;
  font-weight: bold;
`;
