import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

export default styled.div`
  max-width: 1300px;
  margin: 0 auto;
  width: 90%;
  padding: 32px 0;


  ${breakpoints.tablet} {
    padding: 64px 0;
  }
`;
