import scrollTo from 'gatsby-plugin-smoothscroll';

const safeScrollTo = id => {
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  if (typeof window === 'object' && isChrome) {
    return scrollTo(id);
  }

  return document.getElementById(id.substr(1)).scrollIntoView();
};

export default safeScrollTo;
