import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const SectionTitle = ({ title, description, ...other }) => (
  <FlexCenter {...other}>
    <SectionTitleElement>{title}</SectionTitleElement>
    <SectionDescription>{description}</SectionDescription>
  </FlexCenter>
);
export default SectionTitle;

const SectionTitleElement = styled.div`
  font-weight: 600;
  line-height: 36px;
  font-size: 26px;
  text-align: center;

  ${breakpoints.tablet} {
    line-height: 46px;
    font-size: 36px;
  }
`;

const SectionDescription = styled.div`
  font-size: 17px;
  color: ${props => props.theme.secondaryTextColor};
  padding: 8px 0;
  line-height: 32px;
  text-align: center;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;

  ${breakpoints.mobile} {
    padding-bottom: 48px;
  }
`;
