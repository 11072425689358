import styled from 'styled-components';

const DefaultLink = styled.a`
  text-decoration: none;
  font-weight: bold;

  cursor: pointer;
  white-space: nowrap;

  padding-bottom: 1px;

  transition: all 300ms ease;
  text-decoration: none;

  ${props =>
    props.showUnderline &&
    `
    border-bottom: 4px solid #92cdff;

&:hover {
    padding-bottom: 4px;
    border-bottom-color: #92cdff;
  }
`}
`;

export default DefaultLink;

DefaultLink.defaultProps = {
  rel: 'noopener noreferrer',
  target: '_blank',
};
