import styled from 'styled-components';

const Label = styled.div`
  padding: 8px 8px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor || '#eceff5'};
  text-transform: uppercase;
  display: block;
  transition: background 0.1s ease-in;
  white-space: nowrap;
`;

export default Label;
