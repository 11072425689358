import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import breakpoints from '../utils/breakpoints';

const WorkBenefitItem = ({ titleKey, descriptionKey, icon, isColumn }) => (
  <CardItemWrapper isColumn={isColumn}>
    <Image src={icon} alt="icon" />
    <TextWrapper>
      <Title>
        <FormattedMessage id={titleKey} />
      </Title>
      <Description>{<FormattedMessage id={descriptionKey} />}</Description>
    </TextWrapper>
  </CardItemWrapper>
);

export default WorkBenefitItem;

const Image = styled.img`
  width: auto;
  height: 100px;
`;

const CardItemWrapper = styled.div`
  padding: 24px;
  display: flex;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    & > div {
      margin-left: 0;
    }
  }

  ${({ isColumn }) =>
    isColumn &&
    `
  && {
    flex-direction: column;
    text-align: center;

    & > div {
      margin-left: 0;

    }
  }
  `}
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
`;

const TextWrapper = styled.div`
  margin-left: 48px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;

  color: #8698a4;
`;
