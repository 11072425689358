import React from 'react';
import styled from 'styled-components';

export default ({ leftIcon, children, ...other }) => (
  <Button {...other}>
    {leftIcon && <LeftIcon src={leftIcon} alt="logo" />}
    {children}
  </Button>
);

const LeftIcon = styled.img`
  margin-right: 8px;
`;

const Button = styled.button`
  align-items: center;
  border: none;
  white-space: nowrap;
  display: inline-flex;

  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 32px;
  transition: all 0.1s ease 0s;
  background: ${({ backgroundColor }) => backgroundColor || '#fcaf4a'};
  border-radius: 24px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  color: #fff;

  ${props =>
    props.primary &&
    `
      background: #3273dc;
      color: #fff;

      &:hover {
        background: rgb(34, 96, 196);
      }
  `}
`;
