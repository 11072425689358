import React from 'react';
import styled from 'styled-components';

import { Section, NewTabLink } from './common';
import linkedin from '../assets/icon/linkedin.svg';
import medium from '../assets/icon/medium.svg';
import instagram from '../assets/icon/instagram.svg';

const Footer = () => (
  <StyledSection>
    <Text>Continero</Text>
    <IconWrapper>
      <NewTabLink href="https://www.instagram.com/continero">
        <img src={instagram} alt="icon" />
      </NewTabLink>

      <NewTabLink href="https://www.linkedin.com/company/continero">
        <img src={linkedin} alt="icon" />
      </NewTabLink>

      <NewTabLink href="https://medium.com/continero">
        <img src={medium} alt="icon" />
      </NewTabLink>
    </IconWrapper>
  </StyledSection>
);

export default Footer;

const Text = styled.div`
  font-size: 21px;
  line-height: 32px;
  font-weight: bold;
`;

const StyledSection = styled(Section)`
  text-align: center;
`;

const IconWrapper = styled.div`
  margin-top: 16px;
  & > *:not(:last-child) {
    margin-right: 32px;
  }
`;
