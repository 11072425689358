import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';

const TitleHeader = ({
  titleKey,
  descriptionKey,
  center,
  leftComponent,
  marginBottom,
}) => (
  <TitleWrapper center={center} marginBottom={marginBottom}>
    <Flex leftComponent={leftComponent}>
      <Title>
        <FormattedMessage id={titleKey} />
      </Title>
      {leftComponent}
    </Flex>
    {descriptionKey && (
      <SubTitle>
        <FormattedMessage id={descriptionKey} />
      </SubTitle>
    )}
  </TitleWrapper>
);

export default TitleHeader;

const Flex = styled.div`
  margin-bottom: 14px;
  width: 100%;

  ${({ leftComponent }) =>
    leftComponent &&
    `
    display: flex;
    justify-content: space-between;
    `};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;

  ${breakpoints.tablet} {
    font-size: 50px;
    line-height: 42px;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '32px'};
  width: 100%;
  text-align: center;

  ${breakpoints.tablet} {
    text-align: left;
  }

  ${({ center }) => center && 'text-align: center!important;'}
`;

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  position: relative;
  z-index: 2;

  ${breakpoints.tablet} {
    font-size: 24px;
    line-height: 29px;
  }
`;
