import React from 'react';
import { Helmet as ReactHelment } from 'react-helmet';
import ogImage from '../assets/og_image.jpg';

const Helmet = () => (
  <>
    <ReactHelment>
      <title>Continero - Moderní přístup k vývoji a testovaní software</title>
      <meta
        name="title"
        content="Continero - Moderní přístup k vývoji a testovaní software"
      />
      <meta
        name="description"
        content="Kvalitně napsaný a otestovaný kód je základ každého úspěšného projektu"
      />

      <meta property="og:title" content="Continero" />
      <meta
        property="og:description"
        content="Moderní přístup k vývoji a testovaní software"
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://continero.com/" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <meta
        property="og:title"
        content="Continero - Moderní přístup k vývoji a testovaní software"
      />
      <meta
        property="og:description"
        content="Continero - Moderní přístup k vývoji a testovaní software"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <script
        type="text/javascript"
        src="https://cdn.jsdelivr.net/npm/emailjs-com@2.4.0/dist/email.min.js"
      />
    </ReactHelment>
  </>
);

export default Helmet;
