import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    color: ${props => props.theme.primaryTextColor};    
};

html {
    background: #fff;
    min-width: 320px;
    width: 100%;
margin: 0;
    background-color: ${props => props.theme.backgroundColor};
    overflow-x: hidden;
}

body {
    margin: 0;
    overflow-x: hidden;
}
`;

export default GlobalStyles;
