import React from 'react';
import styled from 'styled-components';

export default ({ src, roundCorners, ...other }) => (
  <ImageContainer {...other}>
    <ProductImage src={src} roundCorners={roundCorners} />
  </ImageContainer>
);

export const ImageContainer = styled.div`
  width: 100%;
  padding-top: ${props => (props.ratio ? `${props.ratio}%` : '56.25%')};
  position: relative;
`;

export const ProductImage = styled.div`
  background: ${props => (!props.src ? '#469fff' : `url(${props.src})`)};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #f1f1f1;
  ${props =>
    props.roundCorners && 'border-radius: 7px 7px 0 0;'} position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
